'use client';

import { type FC, useEffect } from 'react';
import { useModal } from '../../../../../shared/hooks/useModal';
import { useSearchParams } from '../../../../../shared/hooks/useSearchParams';

const basePath = process.env.AUTH0_BASE_URL;

export const HomeLogout: FC = () => {
	const { setModal } = useModal();
	const { searchParams, setSearchParams } = useSearchParams();

	useEffect(() => {
		const forcedLogout = searchParams?.get('forced-logout');

		if (forcedLogout === 'true') {
			setModal({
				title: 'Your Dine Rewards account information was changed.',
				htmlString:
					'Please sign-in using your updated credentials to access the latest on your rewards status, points balance and more.',
				primaryButton: {
					label: 'Sign In',
					onClick: handleSignIn,
				},
				id: 'modal-logout',
			});

			setSearchParams({ 'forced-logout': null });
		}
	}, [searchParams]);

	const handleSignIn = () => {
		window.location.href = `${basePath}/api/auth/login`;
	};

	return null;
};
