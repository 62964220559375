'use client';

import { useEffect, useState, type FC } from 'react';
import { useInput } from '../../../../ui/Input/useInput';
import { InputFloating } from '../../../../ui/Input';
import { IconEnvelope } from '@maverick/icons/dist/IconEnvelope';
import { BrandsLogos } from '../../../../../shared/constants/BrandsLogos';
import styles from './HomeHero.module.scss';
import { useEmailValidation } from '../../../../../shared/hooks/useEmailValidation';
import { useRouter } from 'next/navigation';
import { SitecoreService } from '../../../../../shared/services/sitecore/SitecoreHome.service';
import { HomeHeadlineTitles, HomeHeadlineTitlesProps } from '@maverick/entity';

const basePath = process.env.AUTH0_BASE_URL;

export const HomeHero: FC = () => {
	const router = useRouter();
	const email = useInput('');
	const emailIsValid = useEmailValidation(email.value);
	const [title, setTitle] = useState<HomeHeadlineTitles>();

	useEffect(() => {
		const getHowItWorkTitles = async () => {
			const bannerValue = (await SitecoreService.HomeHeroHeadline<HomeHeadlineTitlesProps>()).body;
			setTitle({
				title: bannerValue.item.title.value,
				subtitle: bannerValue.item.subtitle.value,
			});
		};

		getHowItWorkTitles();
	}, []);

	const handleClickSignIn = async () => {
		if (!email.value) return;
		const redirectURL = `${basePath}/api/auth/login?login_hint=${encodeURIComponent(email.value)}`;
		window.location.href = redirectURL;
	};

	const handleClickJoinNow = async () => {
		if (!email.value) return;
		const redirectURL = `${basePath}/api/auth/signup?login_hint=${encodeURIComponent(email.value)}`;
		window.location.href = redirectURL;
	};

	const handleClickMissingPoints = async () => {
		const redirectURL = '/add-a-visit-verify';
		router.push(redirectURL);
	};

	return (
		<div className={styles['container']}>
			<div className={styles['login-container']}>
				<div className={styles['message']}>
					<h1 data-testid='title' className={styles['title-font']} tabIndex={0}>
						{title?.title}
					</h1>
					<p data-testid='subtitle' className={styles['subtitle-font']} tabIndex={0}>
						{title?.subtitle}
					</p>
				</div>
				<div className={styles['form-container']}>
					<form
						data-testid='login-form'
						className={styles['login-form']}
						onSubmit={(e) => e.preventDefault()}
					>
						<div data-testid='input-field-group' className={styles['input-group']}>
							<InputFloating
								type='email'
								name='user-email'
								id='user-email'
								required
								aria-required
								aria-label='Type your email'
								aria-invalid={emailIsValid ? 'false' : 'true'}
								aria-describedby='validation-msg'
								data-testid='input-email'
								className={styles['user-email']}
								label='Type your email'
								labelClassName={styles['input-label']}
								handler={email}
								icon={IconEnvelope}
							/>
							<div
								data-testid='email-valid-msg'
								id='validation-msg'
								className={styles['msg']}
								role='alert'
								aria-live='polite'
							>
								{emailIsValid && <span>Perfect! Let's continue!</span>}
							</div>
						</div>
						<div className={styles['auth-buttons']}>
							<button
								tabIndex={0}
								data-testid='join-now'
								id='join-btn'
								className={styles['join-now']}
								onClick={handleClickJoinNow}
							>
								Join Now
							</button>
							<button
								tabIndex={0}
								data-testid='sign-in'
								id='sign-in-btn'
								className={styles['sign-in']}
								onClick={handleClickSignIn}
							>
								Sign In
							</button>
						</div>
						<div className={styles['separator']}>
							<hr />
							<div className={styles['separator-text-font']} tabIndex={0}>
								OR
							</div>
							<hr />
						</div>
					</form>
					<button
						tabIndex={0}
						data-testid='missing-points'
						id='missing-points-btn'
						className={styles['missing-points']}
						onClick={handleClickMissingPoints}
					>
						Missing Points
					</button>
				</div>
			</div>
			<div className={styles['logos-container']}>
				<img
					data-testid='outback-logo'
					src={BrandsLogos.outback.white.src}
					tabIndex={0}
					alt={BrandsLogos.outback.white.alt}
				/>
				<img
					data-testid='carrabbas-logo'
					src={BrandsLogos.carrabbas.white.src}
					tabIndex={0}
					alt={BrandsLogos.carrabbas.white.alt}
				/>
				<img
					data-testid='bonefish-logo'
					src={BrandsLogos.bonefish.white.src}
					tabIndex={0}
					alt={BrandsLogos.bonefish.white.alt}
				/>
				<img
					data-testid='flemings-logo'
					src={BrandsLogos.flemings.white.src}
					tabIndex={0}
					alt={BrandsLogos.flemings.white.alt}
				/>
			</div>
		</div>
	);
};
