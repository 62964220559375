'use client';
import { FC, ReactNode, useState, useRef, useLayoutEffect } from 'react';
import { CalculateDisplayTime } from '../../../shared/utils/CalculateDisplayTime';
import styles from './Tooltip.module.scss';
import { useIsMobile } from '../../../shared/hooks/useIsMobile';

interface TooltipProps {
	variant?: 'small' | 'large';
	description: string | JSX.Element;
	children: ReactNode;
	id: string;
	forceShowSide?: 'left' | 'right';
}

export const Tooltip: FC<TooltipProps> = ({ variant, description, children, id, forceShowSide }) => {
	const isMobile = useIsMobile();
	const screenPadding = isMobile ? 24 : 32;
	const anchorRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<number | null>(null);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const tooltipTimeout = typeof description === 'string' ? CalculateDisplayTime(description) : 9000;

	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [isLeft, setIsLeft] = useState<boolean>(false);
	const [isTop, setIsTop] = useState<boolean>(false);
	const [tooltipMaxWidth, setTooltipMaxWidth] = useState<number>(0);
	const [anchorHeight, setAnchorHeight] = useState<number>(0);
	const [anchorWidth, setAnchorWidth] = useState<number>(0);

	useLayoutEffect(() => {
		const onResize = () => {
			setShowTooltip(false);

			if (!anchorRef.current) return;

			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;
			const anchorX = anchorRef.current.getBoundingClientRect().left + window.scrollX;
			const anchorY = anchorRef.current.getBoundingClientRect().top;
			const _anchorWidth = anchorRef.current.clientWidth;
			const _anchorHeight = anchorRef.current.clientHeight;

			const isOnLeft =
				(anchorX + _anchorWidth / 2 <= windowWidth / 2 || forceShowSide === 'right') &&
				forceShowSide !== 'left';
			const isOnTop = anchorY <= windowHeight / 2;

			let _tooltipMaxWidth = 0;
			if (isOnLeft) {
				_tooltipMaxWidth = windowWidth - anchorX - screenPadding;
			} else {
				_tooltipMaxWidth = anchorX;
			}

			setTooltipMaxWidth(_tooltipMaxWidth);
			setIsLeft(isOnLeft);
			setIsTop(isOnTop);
			setAnchorWidth(_anchorWidth ?? 0);
			setAnchorHeight(_anchorHeight ?? 0);
		};

		setTimeout(() => {
			onResize();
		}, 2000);

		window.addEventListener('resize', onResize);
		window.addEventListener('scroll', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			window.removeEventListener('scroll', onResize);
		};
	}, [anchorRef?.current]);

	const handleClick = () => {
		if (!isMobile) return;

		if (showTooltip) {
			setShowTooltip(false);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}
			return;
		}

		setShowTooltip(true);
		timeoutRef.current = window.setTimeout(() => {
			setShowTooltip(false);
		}, tooltipTimeout);
	};

	const handleHover = (visible: boolean) => {
		if (!isMobile) setShowTooltip(visible);
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter') handleClick();
	};

	return (
		<div data-testid='container' className={styles['container']}>
			<div
				className={styles['anchor-element']}
				tabIndex={0}
				aria-describedby={id}
				aria-label={isMobile ? 'Click for more details' : undefined}
				ref={anchorRef}
				onClick={handleClick}
				onFocus={() => handleHover(true)}
				onBlur={() => handleHover(false)}
				onMouseEnter={() => handleHover(true)}
				onMouseLeave={() => handleHover(false)}
				onKeyDown={(e) => handleKeyDown(e)}
			>
				{children}
			</div>
			{showTooltip ? (
				<div
					className={styles['tooltip-container']}
					style={{
						width: anchorWidth,
						height: anchorHeight,
						left: isLeft ? `-${anchorWidth / 2}px` : undefined,
						right: !isLeft ? `-${anchorWidth / 2}px` : undefined,
						top: isTop ? `${anchorHeight}px` : undefined,
						bottom: !isTop ? `${anchorHeight}px` : undefined,
						direction: isLeft ? 'ltr' : 'rtl',
						flexDirection: isTop ? 'column' : 'column-reverse',
					}}
					aria-live='polite'
					role='tooltip'
					ref={tooltipRef}
					id={id}
					data-testid='tooltip'
				>
					<div
						className={`${styles['content']} ${variant ? styles[variant] : ''}`}
						style={{ maxWidth: Math.min(tooltipMaxWidth, 440) }}
						data-testid='content'
					>
						<div
							className={`${styles['poligon']} ${styles[isLeft ? 'left' : 'right']} ${
								styles[!isTop ? 'top' : 'bottom']
							}`}
						/>
						<div className={styles['text']} style={{ direction: isLeft ? 'ltr' : 'rtl' }}>
							{description}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
