import { LoggerFrontend } from '@maverick/logger/dist/Logger.frontend';
import { LogData } from '@maverick/logger/dist/Logger';

class DrLog {
	private readonly logger: LoggerFrontend;

	constructor() {
		this.logger = new LoggerFrontend();
	}

	Info(context: string, data: LogData): void {
		this.logger.Info(context, { ...data });
	}

	Warn(context: string, data: LogData): void {
		this.logger.Warn(context, { ...data });
	}

	Error(context: string, data: LogData): void {
		this.logger.Error(context, { ...data });
	}
}

export const Log = new DrLog();
