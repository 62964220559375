import { isDateValid, isEighteenOrOlder, isPasswordValid } from "@maverick/utils";
import { Strings } from "../../../shared/constants/Strings";
import { InputModes } from "./Input";
import { InputHandler } from "./useInput";

export const applyMask = (mask: string, value: string): string => {
    if (!mask) return value;

    if (!value?.length) return '';

    let _value = value.replace(/[^a-zA-Z0-9]/gi, '').split('');
    let _mask = mask.split('');
    for (let i = 0; ; i++) {
        if (i > mask.length) break;

        if (_mask[i] !== 'x') {
            continue;
        } else {
            _mask[i] = _value[0];
            _value.splice(0, 1);

            if (_value.length === 0) {
                const increment = _mask[i + 1] !== 'x' && value.length === i + 2 ? i + 2 : i + 1;
                _mask.splice(increment, _mask.length);
                break;
            }
        }
    }
    return _mask.join('');
};

export const getInputType = (mode: InputModes | undefined) => {
    switch (mode) {
        case InputModes.None:
            return '';
        case InputModes.Password:
        case InputModes.ConfirmPassword:
            return 'password'
        case InputModes.Tel:
            return 'tel';
        case InputModes.ZipCode:
        case InputModes.Money:
        case InputModes.Name:
        case InputModes.BirthDate:
            return 'text';
        default:
            return mode;
    }
};


export const formatMoney = (val: string) => {
	val = val.replace(/\D/g, '').replace(/^0+/, '');
	if (val.length === 1) val = val.replace(/(\d{1})/, '$$0.0$1');
	else if (val.length === 2) val = val.replace(/(\d{2})/, '$$0.$1');
	else val = val.replace(/(\d*)(\d{2})/, '$$$1.$2');
	return val;
};

export const validateBirthDate = async (date: string, handler: InputHandler<string>) => {
    if (!isDateValid(date)) {
        handler.setError(Strings.errors.date.invalid);
        return false;
    }

    if (!isEighteenOrOlder(date)) {
        handler.setError(Strings.errors.date.underEighteen);
        return false;
    }
    handler.setSuccess(Strings.success.greatLetsContinue);
    return true;
}

export const validateZipCode = async (zipCode: string, handler: InputHandler<string>) => {
	const zipCodeLength = 5;
	if (zipCode.length === zipCodeLength) {
		handler.setSuccess(Strings.success.greatLetsContinue);
		return true;
	}
	handler.setError(Strings.errors.zipCode.invalid);
	return false;
}

export const validateName = async (name: string, handler: InputHandler<string>) => {
	if (name.length < 3) {
		handler.setError(Strings.errors.name.tooShort);
        return false;
    }
    handler.setSuccess(Strings.success.greatLetsContinue);
    return true;
}

export const validatePassword = (password: string, handler: InputHandler<string>) => {
	if (password.length === 0) {
		handler.setSuccess('');
		return true;
	}
	if (password.length < 8) {
		handler.setError(Strings.errors.general.incorrectFormatting);
		return false;
	}

	const isValid = isPasswordValid(password);
	if (!isValid) {
		handler.setError(Strings.errors.general.incorrectFormatting);
		return false;
	}

	handler.setSuccess(Strings.success.greatLetsContinue);
	return true;
};