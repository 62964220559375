import { PhoneValidationStatus } from '@maverick/entity';
import { InputHandler } from '../../components/ui/Input/useInput';
import { Strings } from '../constants/Strings';

export const setPhoneMessage = (handler: InputHandler<string>, phoneValidation: PhoneValidationStatus) => {
	switch (phoneValidation) {
		case PhoneValidationStatus.EMPTY:
			handler.setError(Strings.errors.phoneNumber.empty);
			break;
		case PhoneValidationStatus.INVALID:
			handler.setError(Strings.errors.phoneNumber.invalid);
			break;
		default:
			handler.setSuccess(Strings.success.greatLetsContinue);
			break;
	}
}
